<template>
	<v-sheet class="project" id="project" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form
					ref="projectForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row>
						<v-col md="9">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 160px); position: relative"
							>
								<v-row class="px-4">
									<v-col md="3" class="my-auto py-0">
										<label class="btx-label mt-2 required">Name</label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="name"
											placeholder="Name"
											v-model="project.name"
											:rules="[vrules.required(project.name, 'Name')]"
										></TextInput>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label class="btx-label mt-2 required">Customer</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											:items.sync="customerList"
											id="contact"
											placeholder="Customer"
											item-text="display_name"
											item-value="id"
											v-model="project.contact"
											v-on:change="getCustomerProperty"
											:rules="[vrules.required(project.contact, 'Customer')]"
										></SelectInput>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label class="btx-label mt-2 required">Address</label>
									</v-col>
									<v-col md="9" class="py-0">
										<SelectInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											:items.sync="propertyList"
											id="property"
											placeholder="Address"
											item-text="name"
											item-value="id"
											v-model="project.property"
											:rules="[vrules.required(project.property, 'Address')]"
										></SelectInput>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label for="site-number" class="btx-label mt-2">Site Number</label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="site-number"
											placeholder="Site Number"
											v-model="project.site_number"
										></TextInput>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label class="btx-label mt-2">Start Date</label>
									</v-col>
									<v-col md="9" class="py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="purchased-on"
											placeholder="Started On"
											v-model="project.start_date"
										></DatePicker>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label :for="`address-latitude`" class="btx-label mt-2">End Date</label>
									</v-col>
									<v-col md="9" class="py-0">
										<DatePicker
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="retire-date"
											placeholder="Ended On"
											v-model="project.end_date"
											:minDate="project.start_date"
										></DatePicker>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label :for="`address-longitude`" class="btx-label mt-2">Staff</label>
									</v-col>
									<v-col md="9" class="py-0">
										<AutoCompleteInputMember
											hide-details
											:multiple="true"
											:items.sync="memberList"
											item-value="id"
											placeholder="Staffs"
											v-model="project.members"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
										</AutoCompleteInputMember>
									</v-col>
									<v-col md="3" class="mt-2 py-0">
										<label class="btx-label mt-2">Description</label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextAreaInput
											hide-details
											placeholder="Description"
											v-model="project.description"
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
									<v-col md="3" class="my-auto py-0">
										<label for="attachment" class="btx-label mt-2">Attachment</label>
									</v-col>
									<v-col md="9" class="py-0">
										<template v-for="(data, index) in project.attachment">
											<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
												<v-col md="5" class="py-0">
													<v-file-input
														v-model="data.file"
														placeholder="Click here to select files"
														color="blue darken-4"
														multiple
														outlined
														class="mt-3"
														prepend-icon=""
														prepend-inner-icon="mdi-attachment"
														hide-details
														v-on:change="limitAttachment($event, index)"
													></v-file-input>
												</v-col>
												<v-col md="5" class="py-0">
													<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
												</v-col>
												<v-col md="1" class="py-0">
													<v-btn
														:disabled="project.attachment.length < 2"
														v-on:click="removeFile(index)"
														class="white--text mt-3"
														depressed
														color="red darken-4"
														tile
														style="margin-left: -10px"
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</v-col>
												<v-col md="1" class="py-0">
													<v-btn
														:disabled="project.attachment.length > 2"
														v-on:click="addMore()"
														class="white--text mt-3"
														depressed
														color="blue darken-4"
														tile
														style="margin-left: -13px"
													>
														<v-icon>mdi-plus</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</v-col>
									<v-col md="3" class="py-0">
										<label for="remark" class="btx-label mt-2">Remark</label>
									</v-col>
									<v-col md="9" class="py-0">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="remark"
											placeholder="Remark"
											v-model="project.remark"
										></TextAreaInput>
									</v-col>
								</v-row>
							</perfect-scrollbar>
						</v-col>
						<v-col md="3"> </v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { GetCustomerList, GetContactProperty } from "@/core/lib/contact.lib";
import { GetMemberList } from "@/core/lib/member.lib";
import { UploadFiles } from "@/core/lib/upload.lib";
import { UpdateProject, CreateProject, GetProject } from "@/core/lib/project.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectInput from "@/view/components/SelectInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInputMember from "@/view/components/AutoCompleteInputMember";
import { map } from "lodash";
import TextInput from "@/view/components/TextInput";
import ObjectPath from "object-path";

export default {
	name: "project-create",
	title: "Create Project",
	data() {
		return {
			uuid: null,
			barcode: null,
			formValid: true,
			disabled: false,
			pageLoading: false,
			attachment: [],
			propertyList: [],
			customerList: [],
			memberList: [],
			project: {
				id: null,
				uuid: null,
				contact: null,
				site_number: null,
				property: null,
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				remark: null,
				start_date: null,
				end_date: null,
				members: [],
				description: null,
			},
		};
	},
	components: {
		TextAreaInput,
		SelectInput,
		DatePicker,
		TextInput,
		AutoCompleteInputMember,
	},
	methods: {
		addMore() {
			this.project.attachment.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.project.attachment.splice(index, 1);
			if (this.project.attachment.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.project.attachment[index].url = ObjectPath.get(response, "0.path", null);
					this.project.attachment[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCustomers() {
			const _this = this;
			GetCustomerList("customer")
				.then((data) => {
					_this.customerList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},
		getCustomerProperty(customerUuid) {
			GetContactProperty(customerUuid)
				.then((data) => {
					this.propertyList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},
		getMemberList() {
			GetMemberList()
				.then((data) => {
					this.memberList = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.dataLoading = false;
				});
		},
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.projectForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.projectForm.validate()) {
				return false;
			}

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateProject(_this.uuid, this.project);

					_this.$router.replace({
						name: "project-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project has been updated." },
					]);
				} else {
					const { uuid } = await CreateProject(this.project);

					_this.$router.replace({
						name: "project-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Project has been created." },
					]);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getProject() {
			GetProject(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.project.contact = data.contact && data.contact.id ? data.contact.id : 0;
					this.project.property = data.property && data.property.id ? data.property.id : 0;
					this.project.start_date = data.start_date;
					this.project.site_number = data.site_number;
					this.project.end_date = data.end_date;
					this.project.description = data.description;
					this.project.name = data.name;
					if (data.members && data.members.length > 0) {
						let memberArr = map(data.members, function (row) {
							return row.member.id;
						});
						this.project.members = memberArr;
					}

					if (this.project.contact > 0) {
						this.getCustomerProperty(this.project.contact);
					}

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Project", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.project.name;
			}
			return "Create new Project";
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Project", disabled: true },
			{ text: "Create", disabled: true },
		]);
		this.getCustomers();
		this.getMemberList();

		const { name, params } = this.$route;
		if (name === "project-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getProject();
		}
	},
	computed: {
		...mapGetters(["errors"]),
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
